import { FC, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { DataTable, Box, Icon, Input, Button } from "@ntpkunity/controls";
import { TablePagination, useTheme } from "@mui/material";
import ChevronDown from "../../../shared/assets/images/chevron-down";
import ChartTable from "./chart-table";
import {
  ICriteria,
  IFinanceType,
  ICustomerType,
  IAssetCategory,
  IAssetType,
  IProductType,
} from "Interface";
import { ChartTypes } from "Enums";
import { PaginationWrap } from "@sharedComponents";

const MasterTable: FC<{
  onOptionsClick: (key, value) => unknown;
  onEditCriteria: (data) => unknown;
  chartsFilter?: any;
  setColumnFilters: any;
  setPageSize: any;
  setPageNumber: any;
  pageSize: number;
  pageNumber: number;
  chartType: number;
  criteria: ICriteria[];
  AllFinanceType?: IFinanceType[];
  AllCustomerType?: ICustomerType[];
  AllAssetCategory?: IAssetCategory[];
  AllAssetType?: IAssetType[];
  AllProductType?: IProductType[];
}> = ({
  onOptionsClick,
  onEditCriteria,
  chartsFilter,
  setColumnFilters,
  setPageSize,
  setPageNumber,
  pageSize,
  pageNumber,
  chartType,
  criteria,
  AllFinanceType,
  AllCustomerType,
  AllAssetCategory,
  AllAssetType,
  AllProductType,
}) => {
  const [nameFilter, setNameFilter] = useState("");
  const [assetsFilter, setAssetsFilter] = useState("");
  const [assetConditionFilter, setAssetConditionFilter] = useState("");
  const [termsFilter, setTermsFilter] = useState("");
  const [creditsFilter, setCreditsFilter] = useState("");
  const [financedTypeFilter, setFinanceTypeFilter] = useState("");
  const [customerTypeFilter, setCustomerTypeFilter] = useState("");
  const [assetCategoryFilter, setAssetCategoryFilter] = useState("");
  const [assetTypeFilter, setAssetTypeFilter] = useState("");
  const [productTypeFilter, setProductTypeFilter] = useState("");
  const [marginFilter, setMarginFilter] = useState("");
  const [financedAmountFilter, setFinancedAmountFilter] = useState("");
  const [commissionRangeFilter, setCommissionRangeFilter] = useState("");
  const [downPaymentRangeFilter, setDownPaymentRangeFilter] = useState("");
  const [downPaymentFilter, setDownPaymentFilter] = useState("");
  const [commissionFilter, setCommissionFilter] = useState("");
  const [glSplit, setGlSplit] = useState("");
  const [customerSplit, setCustomerSplit] = useState("");
  const [manufacturerSplit, setManufacturerSplit] = useState("");
  const [salesRepresentativeSplit, setSalesRepresentativeSplit] = useState("");
  const [calculationBasisFilter, setCalculationBasisFilter] = useState("");
  const [financierRateFilter, setFinancierRateFilter] = useState("");
  const [RateFilter, setRateFilter] = useState("");
  const [dealerRateFilter, setDealerRateFilter] = useState("");
  const [manufacturerRateFilter, setManufacturerRateFilter] = useState("");
  const [assetClassificationFilter, setAssetClassificationFilter] =
    useState("");
  const [assetUsageFilter, setAssetUsageFilter] = useState("");
  const [purchaseOption, setPurchaseOption] = useState("");

  const [statusFilter, setStatusFilter] = useState("");
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}`;
    if (nameFilter.trimStart() != null && nameFilter.trimStart() != "") {
      query_string = query_string.concat(`&name=${nameFilter}`);
    }
    if (
      assetClassificationFilter.trimStart() != null &&
      assetClassificationFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&asset_classification=${assetClassificationFilter}`
      );
    }
    if (assetsFilter.trimStart() != null && assetsFilter.trimStart() != "") {
      query_string = query_string.concat(`&assets=${assetsFilter}`);
    }
    if (
      financedTypeFilter.trimStart() != null &&
      financedTypeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&finance_types=${financedTypeFilter}`
      );
    }
    if (
      customerTypeFilter.trimStart() != null &&
      customerTypeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&customer_type=${customerTypeFilter}`
      );
    }
    if (
      assetCategoryFilter.trimStart() != null &&
      assetCategoryFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&asset_category=${assetCategoryFilter}`
      );
    }
    if (
      assetTypeFilter.trimStart() != null &&
      assetTypeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&asset_type=${assetTypeFilter}`);
    }
    if (
      productTypeFilter.trimStart() != null &&
      productTypeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&product_types=${productTypeFilter}`);
    }
    if (
      assetConditionFilter.trimStart() != null &&
      assetConditionFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&asset_condition=${assetConditionFilter}`
      );
    }
    if (
      assetUsageFilter.trimStart() != null &&
      assetUsageFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&asset_usages=${assetUsageFilter}`);
    }
    if (
      financedAmountFilter.trimStart() != null &&
      financedAmountFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&financed_amount=${financedAmountFilter}`
      );
    }
    if (termsFilter.trimStart() != null && termsFilter.trimStart() != "") {
      query_string = query_string.concat(`&terms=${termsFilter}`);
    }
    if (creditsFilter.trimStart() != null && creditsFilter.trimStart() != "") {
      query_string = query_string.concat(`&credit_score=${creditsFilter}`);
    }
    if (
      downPaymentFilter.trimStart() != null &&
      downPaymentFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&down_payment=${downPaymentFilter}`);
    }
    if (
      downPaymentRangeFilter.trimStart() != null &&
      downPaymentRangeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&down_payment_range=${downPaymentRangeFilter}`
      );
    }
    if (
      commissionFilter.trimStart() != null &&
      commissionFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&commission=${commissionFilter}`);
    }
    if (
      commissionRangeFilter.trimStart() != null &&
      commissionRangeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&commission_range=${commissionRangeFilter}`
      );
    }
    if (
      purchaseOption.trimStart() != null &&
      purchaseOption.trimStart() != ""
    ) {
      query_string = query_string.concat(`&purchase_option=${purchaseOption}`);
    }
    if (
      calculationBasisFilter.trimStart() != null &&
      calculationBasisFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&calculation_basis=${calculationBasisFilter}`
      );
    }
    if (glSplit.trimStart() != null && glSplit.trimStart() != "") {
      query_string = query_string.concat(`&gl_split=${glSplit}`);
    }
    if (customerSplit.trimStart() != null && customerSplit.trimStart() != "") {
      query_string = query_string.concat(`&customer_split=${customerSplit}`);
    }
    if (
      manufacturerSplit.trimStart() != null &&
      manufacturerSplit.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&manufacturer_split=${manufacturerSplit}`
      );
    }
    if (
      salesRepresentativeSplit.trimStart() != null &&
      salesRepresentativeSplit.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&sales_representative_split=${salesRepresentativeSplit}`
      );
    }
    if (
      financierRateFilter.trimStart() != null &&
      financierRateFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&financier_rate=${financierRateFilter}`
      );
    }
    if (RateFilter.trimStart() != null && RateFilter.trimStart() != "") {
      query_string = query_string.concat(`&rate=${RateFilter}`);
    }
    if (
      dealerRateFilter.trimStart() != null &&
      dealerRateFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&dealer_rate=${dealerRateFilter}`);
    }
    if (
      manufacturerRateFilter.trimStart() != null &&
      manufacturerRateFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&manufacturer_rate=${manufacturerRateFilter}`
      );
    }
    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled".startsWith(
          statusFilter.toLowerCase().slice(0, Math.max("enabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${true}`);
      } else if (
        "disabled".startsWith(
          statusFilter
            .toLowerCase()
            .slice(0, Math.max("disabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${false}`);
      } else {
        query_string = query_string.concat(`&is_active=None`);
      }
    }
    return query_string;
  };

  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };

  const theme = useTheme();

  return (
    <>
      <DataTable
        theme={theme}
        theadChildren={
          <>
            <TableRow>
              <TableCell>Chart Name </TableCell>
              {/* <TableCell>Asset Classification</TableCell> */}
              {chartType != ChartTypes.FeesChart && (
                <TableCell className="w-auto">Asset Make/Model/Trim</TableCell>
              )}
              <TableCell>Asset Condition</TableCell>
              {chartType != ChartTypes.FeesChart && (
                <TableCell>Asset Usage</TableCell>
              )}

              <TableCell>Terms</TableCell>
              {chartType != ChartTypes.FeesChart && (
                <TableCell>Credit Score </TableCell>
              )}
              <TableCell>Financed Amount</TableCell>
              {(chartType == ChartTypes.CommissionChart ||
                chartType == ChartTypes.FeesChart) && (
                <>
                  <TableCell>Finance Type</TableCell>
                  <TableCell>Customer Type</TableCell>
                  <TableCell>Asset Category</TableCell>
                  <TableCell>Asset Type</TableCell>
                  <TableCell>Product Type</TableCell>
                </>
              )}
              {chartType == ChartTypes.CommissionChart && (
                <>
                  <TableCell>Purchase Option</TableCell>
                  <TableCell>Calculation Basis</TableCell>
                  <TableCell>Commission</TableCell>
                  <TableCell>Commission Range</TableCell>
                  {/* <TableCell>GL</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Manufacturer</TableCell>
                  <TableCell>Sales Representative</TableCell> */}
                </>
              )}

              {chartType == ChartTypes.DownPaymentChart && (
                <>
                  <TableCell>Down Payment</TableCell>
                  <TableCell>Down Payment Range</TableCell>
                </>
              )}
              {chartType == ChartTypes.SubsidyChart && (
                <>
                  <TableCell>Rate</TableCell>
                  <TableCell>Financier Rate</TableCell>
                  <TableCell>Dealer Rate</TableCell>
                  <TableCell>Manufacturer Rate</TableCell>
                </>
              )}
              <TableCell>Status</TableCell>
              <TableCell className="action-cell fixed-cell space-fixed" />
            </TableRow>
            <TableRow className="filters-row">
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setNameFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={nameFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              {/* <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setAssetClassificationFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={assetClassificationFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell> */}
              {chartType != ChartTypes.FeesChart && (
                <>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setAssetsFilter(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={assetsFilter?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                </>
              )}

              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setAssetConditionFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={assetConditionFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>

              {chartType != ChartTypes.FeesChart && (
                <>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setAssetUsageFilter(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={assetUsageFilter?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                </>
              )}
              {(chartType == ChartTypes.CommissionChart ||
                chartType == ChartTypes.FeesChart) && (
                <>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setFinanceTypeFilter(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={financedTypeFilter?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setCustomerTypeFilter(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={customerTypeFilter?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setAssetCategoryFilter(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={assetCategoryFilter?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setAssetTypeFilter(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={assetTypeFilter?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setProductTypeFilter(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={productTypeFilter?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                </>
              )}

              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setTermsFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={termsFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              {chartType != ChartTypes.FeesChart && (
                <>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setCreditsFilter(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={creditsFilter?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                </>
              )}

              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setFinancedAmountFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={financedAmountFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>

              {chartType == ChartTypes.DownPaymentChart && (
                <>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setDownPaymentFilter(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={downPaymentFilter?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setDownPaymentRangeFilter(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={downPaymentRangeFilter?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                </>
              )}

              {chartType == ChartTypes.CommissionChart && (
                <>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setPurchaseOption(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={purchaseOption?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setCalculationBasisFilter(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={calculationBasisFilter?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setCommissionFilter(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={commissionFilter?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setCommissionRangeFilter(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={commissionRangeFilter?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                  {/* <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setGlSplit(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={glSplit?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell> */}
                  {/* <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setCustomerSplit(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={customerSplit?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setManufacturerSplit(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={manufacturerSplit?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setSalesRepresentativeSplit(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={salesRepresentativeSplit?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell> */}
                </>
              )}
              {chartType == ChartTypes.SubsidyChart && (
                <>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setRateFilter(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={RateFilter?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setFinancierRateFilter(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={financierRateFilter?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setDealerRateFilter(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={dealerRateFilter?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(value) => {
                          setManufacturerRateFilter(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                        value={manufacturerRateFilter?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                </>
              )}
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setStatusFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={statusFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>

              <TableCell className="action-cell fixed-cell space-fixed" />
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            {chartsFilter &&
              chartsFilter?.result
                ?.filter((x) => x.chart_type_id == chartType)
                ?.map((item, index) => (
                  <ChartTable
                    key={index}
                    chart={item}
                    onOptionsClick={(key, value) => onOptionsClick(key, value)}
                    onEditCriteria={(data) => onEditCriteria(data)}
                    criteria={criteria}
                    chartType={chartType}
                  />
                ))}
          </>
        }
      />
      <PaginationWrap>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={chartsFilter ? chartsFilter?.total_results : -1}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            IconComponent: ChevronDown,
            MenuProps: { disablePortal: true },
          }}
        />
      </PaginationWrap>
    </>
  );
};

export default MasterTable;
