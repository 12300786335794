import { FC } from "react";
import { useTheme } from "@mui/material";
import { Box, Grid, Textarea, Typography } from "@ntpkunity/controls";
import { TextAreaWrap } from "@sharedComponents";

const DealerChat: FC<{}> = () => {
  const theme = useTheme();
  return (
    <>
      <Grid theme={theme} container columnSpacing={2} rowSpacing={3}>
        <Grid theme={theme} item sm={6} xs={12}>
          <TextAreaWrap className="chat-wrap">
            <Textarea
              theme={theme}
              fullWidth={true}
              type={"text"}
              id={"WelcomeMessage"}
              label="Welcome Message"
              placeholder="Type here.."
            />
            <Typography
              theme={theme}
              variant="caption"
              component="div"
              textAlign="right"
              className="counter"
              children="0/200"
            />
          </TextAreaWrap>
        </Grid>
        <Grid theme={theme} item sm={6} xs={12}>
          <TextAreaWrap className="chat-wrap">
            <Textarea
              theme={theme}
              fullWidth={true}
              type={"text"}
              id={"OfflineMessage"}
              label="Offline Message"
              placeholder="Type here.."
            />
            <Typography
              theme={theme}
              variant="caption"
              component="div"
              textAlign="right"
              className="counter"
              children="0/200"
            />
          </TextAreaWrap>
        </Grid>
      </Grid>
    </>
  );
};

export default DealerChat;
