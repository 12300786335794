import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Icon, Box, Button } from "@ntpkunity/controls";
import { ConfirmationDialog, DataTableWrap } from "@sharedComponents";
import { setOpenDrawer } from "../../Stores/SetupStore/Actions";
import { useSetupsStore } from "../../Stores/SetupStore/Store";
import { ActionOptions, DialogMessages, QueryKeys } from "Enums";
import AddNewIntegration from "../../components/AddNewIntegrations/AddNewIntegrations";
import IntegrationTable from "./integration-table";
import {
  useDeleteLenderIntegration,
  UseQuery_getLenderIntegrations,
  UseDeleteHubexCredentials,
  UseQuery_checkTenantConfiguration,
} from "services";
import { ProviderMapping } from "modules";

const Integrations = () => {
  UseQuery_checkTenantConfiguration(
    JSON.parse(localStorage.getItem("settings"))?.tenant_id
  );
  const { mutate: deleteLenderIntegration } = useDeleteLenderIntegration();
  const { mutateAsync: deleteHubexCredentials } = UseDeleteHubexCredentials();
  const [IntegrationState, setIntegrationStateState] = useState<boolean>(false);
  const [ParentIntegrationState, setParentIntegrationState] =
    useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>("Add New Integration");
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [state, dispatch] = useSetupsStore();
  const [actionData, setActionData] = useState<any>();
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const [providerMappingState, setProviderMappingState] = useState(false)
  const [providerId, setProviderId] = useState(undefined)


  const theme = useTheme();
  const { data: allIntegrations } = UseQuery_getLenderIntegrations();

  useEffect(() => {
    if (IntegrationState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [IntegrationState]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setParentIntegrationState(false);
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Integration");
        setActionData(value);
        setIntegrationStateState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };

  const onEdit = (formData) => {
    setActionType(ActionOptions.EDIT);
    setActionData(formData);
    setActionTitle("Add New Provider");
    setIntegrationStateState(true);
    dispatch(setOpenDrawer(true));
  };
  const onDelete = (formData) => {
    setActionData(formData);
    setConfirmationPopupState(true);
  };
  const onProviderMapping = (id) => {
    setProviderMappingState(true)
    setProviderId(id)
  }

  const onDeleteConfirm = (e: any) => {
    deleteLenderIntegration(actionData?.id, {
      onSuccess: (data, variables, context) => {
        deleteHubexCredentials({ provider_name: actionData?.provider_name });
        setActionData(undefined);
      },
    });
  };

  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box theme={theme} className="scroll-hide spr-border" />
        </Box>
        <IntegrationTable
          onEdit={(data) => onEdit(data)}
          onDelete={(data) => onDelete(data)}
          onProviderMapping={(id) => onProviderMapping(id)}
          setParentIntegrationState={setParentIntegrationState}
        />
        <Button
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Integration"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        ></Button>

        {IntegrationState && (
          <AddNewIntegration
            openPopUp={IntegrationState}
            setPopUpState={setIntegrationStateState}
            title={actionTitle}
            actionType={actionType}
            integrationTypeData={actionData}
            ParentIntegrationState={ParentIntegrationState}
            allIntegrations={allIntegrations}
          />
        )}
      </DataTableWrap>
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
      {
        providerMappingState && (
        <ProviderMapping providerId={providerId} setProviderMappingState={setProviderMappingState} />
        )
      }
    </>
  );
};
export default Integrations;
