import { FC, useEffect, useState, useMemo } from "react";
import { Controller } from "react-hook-form";
import { useTheme } from "@mui/material";
import { Select, Input, MultiSelect, Autocomplete } from "@ntpkunity/controls";
import { MultiSelectWrap } from "@sharedComponents";
import { decimalPlaceValidation, validation } from "shared/helper/methods";
import {
  useSaveDealerFee,
  useUpdateDealerFeeById,
  useGetFees,
  useGetStates,
} from "services/dealer-configurations.service";
import { useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import { IDealerFee } from "Interface";
import { DEALER_FEE_CONFIG_MSG } from "shared/config/constants";

interface IFormInputs {
  id?: number;
  fee_name: string;
  states: number[];
  default_amount: number;
  taxable: boolean;
  is_active: boolean;
  dealer_code: string;
  applicable_finance_type: string[];
  vendor?: string;
  event: string;
  calculation_type: string;
  multiplication_factor: number;
}

const DealerFeeDrawer: FC<{
  form: any;
  formData?: IFormInputs;
  isEditMode: boolean;
  dealer_code: string;
}> = ({ form, formData, isEditMode, dealer_code }) => {
  const { actions } = useStoreContext();
  const theme = useTheme();
  const { mutate: SaveDealeFee } = useSaveDealerFee();
  const { mutate: updateDealerFee } = useUpdateDealerFeeById();
  const { mutate: getStates, data: statesData } = useGetStates();
  const { mutate: getFees, data: feeData } = useGetFees();
  const [stateNames, setStateNames] = useState<string[]>([]);
  const queryClient = useQueryClient();

  const onSubmit = async (data: any) => {
    if (data) {
      const multiplication_factor =
        data?.calculation_type === "Fixed amount"
          ? null
          : data?.multiplication_factor || null;
      // data.fee_name = feeName;
      if (isEditMode) {
        updateDealerFee(
          {
            ...data,
            multiplication_factor,
            dealer_code: dealer_code,
          },
          {
            onSuccess(response: IDealerFee) {
              reset();
            },
            onError(error: any) {
              if (error?.response?.data?.detail?.code != 400) {
                actions.setToast({
                  toastMessage: error?.error?.response?.data?.detail?.message,
                  toastState: true,
                  variant: "error",
                });
              }
            },
          }
        );
      } else {
        SaveDealeFee(
          {
            ...data,
            multiplication_factor,
            dealer_code: dealer_code,
            default_amount: +data.default_amount,
          },
          {
            onSuccess(response: IDealerFee) {
              actions.addDealerFeeRow({
                ...response,
                state_name: stateNames.join(","),
              });
              actions.setToast({
                toastMessage: ToastMessages.Record_Added_Success,
                toastState: true,
              });
              reset();
            },
            onError: async (error: any) => {
              if (error?.response?.data?.detail?.code != 400) {
                actions.setToast({
                  toastMessage: error?.error?.response?.data?.detail?.message,
                  toastState: true,
                  variant: "error",
                });
              }
            },
          }
        );
      }
    }
  };
  const {
    control,
    reset,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
  } = form;

  const calculationType = watch("calculation_type");
  const [feeName, setFeeName] = useState<string>();
  useEffect(() => {
    getStates();
    getFees();
  }, []);
  useEffect(() => {
    onSubmit(formData);
  }, [formData]);
  const isDisabled = useMemo(() => {
    return calculationType === "Fixed amount";
  }, [calculationType]);

  const allCurrencies: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_CURRENCIES
  );
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const [defaultCurrency, setDefaultCurrency] = useState<string>("");

  useEffect(() => {
    const filteredCurrency = allCurrencies?.filter(
      (currency) => currency.id === companyProfile?.default_currency
    );
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0
        ? filteredCurrency[0].symbol
        : ""
    );
  }, [companyProfile]);
  return (
    <>
      <Controller
        name="fee_name"
        control={control}
        defaultValue={""}
        rules={validation("Fee Name", true)}
        render={({ field }) => (
          <Autocomplete
            disablePortal
            freeSolo={true}
            theme={theme}
            items={feeData?.map((option: any) => {
              return { label: option.name };
            })}
            inputValue={field?.value}
            options={feeData?.map((option: any) => {
              return { label: option.name };
            })}
            getOptionLabel={(option: any) =>
              option.label ? option.label : field.value
            }
            onInputChange={(_e: any, value) => {
              setFeeName(value);
              setValue("fee_name", value);
              field.onChange(_e);
            }}
            {...field}
            onChange={(_e: any, value: any) => {
              setFeeName(value?.label);
              setValue("fee_name", value?.label);
            }}
            sx={{ mb: 2 }}
            error={errors?.fee_name?.message}
            label="Fee Name"
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.id}>
                <span>{option.label}</span>
              </li>
            )}
          />
        )}
      />
      <MultiSelectWrap>
        <Controller
          name="states"
          control={control}
          rules={validation("State Name", true)}
          defaultValue={[]}
          render={({ field: { value, onChange } }) => (
            <MultiSelect
              theme={theme}
              label={"State Name"}
              items={statesData?.map((state: any) => {
                return { value: state.id, text: state.name };
              })}
              value={value}
              onChange={(e: any) => {
                setStateNames(
                  e.target.value.map(
                    (val: number) =>
                      statesData.find((state_id: any) => state_id.id === val)
                        ?.name
                  )
                );
                onChange(e);
              }}
              selectError={errors?.states?.message}
              sxProps={""}
            />
          )}
        />
      </MultiSelectWrap>
      <MultiSelectWrap>
        <Controller
          name="applicable_finance_type"
          control={control}
          defaultValue={[]}
          rules={validation("Applicable Finance Type(s)", true)}
          render={({ field }) => (
            <MultiSelect
              id="applicationFinanceType"
              name="applicable_finance_type"
              theme={theme}
              label={"Applicable Finance Type(s)"}
              items={[
                { text: "Lease", value: "Lease" },
                { text: "Finance", value: "Finance" },
              ]}
              {...field}
              value={field.value}
              selectError={errors?.applicable_finance_type?.message}
            />
          )}
        />
      </MultiSelectWrap>
      <Controller
        name="event"
        control={control}
        defaultValue={""}
        rules={{
          validate: (value) => {
            if (value === "") {
              return "Event is required";
            }
            return true;
          },
        }}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Event"}
            items={[
              { text: "End of Term", value: "End of Term" },
              { text: "Inception", value: "Inception" },
            ]}
            value={value}
            sxProps={""}
            onChange={(e) => {
              onChange(e);
            }}
            selectError={errors?.event?.message}
          ></Select>
        )}
      />
      <Controller
        name="calculation_type"
        control={control}
        defaultValue={""}
        rules={{
          validate: (value) => {
            if (value === "") {
              return "Calculation Type is required";
            }
            return true;
          },
        }}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Calculation Type"}
            items={[
              { text: "Fixed amount", value: "Fixed amount" },
              { text: "Monthly Payment based", value: "Monthly Payment based" },
            ]}
            value={value}
            sxProps={""}
            onChange={(e) => {
              if (e.target.value === "Fixed amount")
                setValue("multiplication_factor", undefined);
              onChange(e);
            }}
            selectError={errors?.calculation_type?.message}
          ></Select>
        )}
      />
      <Controller
        name="default_amount"
        control={control}
        rules={validation("Default Amount", true)}
        render={({ field }) => (
          <Input
            theme={theme}
            label={"Default Amount"}
            fullWidth
            startAdornment={
              <>
                <span className="adornment-text">{defaultCurrency}</span>
              </>
            }
            {...field}
            type="number"
            error={errors?.default_amount?.message}
          />
        )}
      />
      <Controller
        name="multiplication_factor"
        control={control}
        rules={{
          required: {
            value: !isDisabled,
            message: `Multiplication Factor is required`,
          },
        }}
        render={({ field }) => (
          <Input
            theme={theme}
            label={"Multiplication Factor"}
            fullWidth
            {...field}
            onChange={(val) => {
              const formattedValue = decimalPlaceValidation(val, 2);

              setValue(
                "multiplication_factor",
                formattedValue ? parseFloat(formattedValue) : undefined
              );

              if (errors?.multiplication_factor?.message && val)
                clearErrors("multiplication_factor");
            }}
            type="text"
            error={!isDisabled && errors?.multiplication_factor?.message}
            disabled={isDisabled}
            helperText={!isDisabled && DEALER_FEE_CONFIG_MSG}
            masking
            maskDecimalScale={2}
            maskNumeric
          />
        )}
      />
      <Controller
        name="fee_handling"
        control={control}
        defaultValue={""}
        rules={{
          validate: (value) => {
            if (value === "") {
              return "Fee Handling is required";
            }
            return true;
          },
        }}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Fee Handling"}
            items={[
              { text: "Financed", value: "Financed" },
              { text: "Upfront", value: "Upfront" },
            ]}
            value={value}
            sxProps={""}
            onChange={(e) => {
              onChange(e);
            }}
            selectError={errors?.fee_handling?.message}
          ></Select>
        )}
      />
      <Controller
        name="taxable"
        control={control}
        defaultValue={""}
        rules={{
          validate: (value) => {
            if (value === "") {
              return "Taxable is required";
            }
            return true;
          },
        }}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Taxable"}
            items={[
              { text: "Yes", value: true },
              { text: "No", value: false },
            ]}
            value={value}
            sxProps={""}
            onChange={(e) => {
              onChange(e);
            }}
            selectError={errors?.taxable?.message}
          ></Select>
        )}
      />
      <Controller
        name="vendor"
        control={control}
        rules={{
          pattern: {
            value: /^[A-Za-z\s]*$/,
            message: "Only characters are allowed",
          },
        }}
        render={({ field }) => (
          <Input
            theme={theme}
            label={"Vendor (Optional)"}
            fullWidth
            {...field}
            type="string"
            error={errors?.vendor?.message}
          />
        )}
      />
      <Controller
        name="external_code"
        control={control}
        render={({ field }) => (
          <Input
            theme={theme}
            label={"External Code (Optional)"}
            fullWidth
            {...field}
            type="string"
            error={errors?.external_code?.message}
          />
        )}
      />
      <Controller
        name="is_active"
        control={control}
        defaultValue={true}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Status"}
            items={[
              { text: "Enabled", value: true },
              { text: "Disabled", value: false },
            ]}
            value={value}
            sxProps={""}
            onChange={onChange}
          ></Select>
        )}
      />
    </>
  );
};

export default DealerFeeDrawer;
