import { FC, useState, useEffect, useCallback } from "react";
import { LayoutWithSideNav } from "../layout";
import { useTheme } from "@mui/material";
import {
  Icon,
  Menu,
  Box,
  Grid,
  DuoTab,
  Button,
  Typography,
  Dialog,
} from "@ntpkunity/controls";
import { DuoTabWrap, PageHeader, ConfirmationDialog } from "@sharedComponents";
import { useForm } from "react-hook-form";
import {
  ProgramGeneral,
  FinancialParameter,
  MetricsAssociation,
  AssetGroupTable,
  Dealers,
  ProgramCommission,
  ProgramSubsidy,
  ProgramEndOfTerm,
} from "modules";
import { logout } from "@ntpkunity/controls-ums";
import {
  UseMutation_AddProgram,
  UseMutation_UpdateProgram,
  UseMutation_CheckDuplicateProgramName,
  UseMutation_CheckDuplicateProgramCode,
  UseMutation_findProgramById,
  UseQuery_getAllCharts,
} from "services";
import {
  IProgram,
  IFpAssetGroup,
  IFpGeneral,
  IFpFinancialParameter,
  IFpChartsAssociation,
  IProgramFilter,
  ICharts,
  IProgramCommission,
  IProgramSubsidy,
  IProgramEndOfTerm,
} from "Interface";
import { FpChartsAssociation } from "models";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { addFpAssetGroup, removeFpAssetGroup } from "Stores/SetupStore/Actions";
import {
  ChartTypes,
  ActionOptions,
  ValidationMessages,
  QueryKeys,
  ToastMessages,
  DialogMessages,
  CommissionType,
  ProgramState,
} from "Enums";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "shared/config/links";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { debounce } from "utilities/common";
import { useQueryClient } from "react-query";
import { usePrompt } from "utilities/usePrompt";
import { ContactEmergency } from "@mui/icons-material";

const schema = yup.object().shape({
  financial_product_general: yup.object().shape({
    name: yup.string().nullable().required(ValidationMessages.FP_NAME),
    lender_id: yup.string().nullable().required(ValidationMessages.LENDER_NAME),
    financial_product_code: yup
      .string()
      .nullable()
      .required(ValidationMessages.FP_ID),
    finance_type: yup
      .string()
      .nullable()
      .required(ValidationMessages.FP_FINANCE_TYPE),
    validity_start_date: yup
      .string()
      .typeError(ValidationMessages.VALIDITY_START_DATE)
      .required(ValidationMessages.VALIDITY_START_DATE),
  }),
  financial_product_financial_parameter: yup
    .object()
    .shape({
      rental_frequency: yup
        .array()
        .min(1, ValidationMessages.RENTAL_FREQUENCY_REQUIRED)
        .nullable()
        .required(ValidationMessages.RENTAL_FREQUENCY_REQUIRED),
      interest_type: yup
        .string()
        .nullable()
        .required(ValidationMessages.INTEREST_TYPE_REQUIRED),
      interest_rate_override: yup
        .boolean()
        .nullable()
        .required(ValidationMessages.INTEREST_RATE_OVERRIDE),
      calculation_method: yup
        .string()
        .nullable()
        .required(ValidationMessages.CALCULATION_METHOD),
      rental_mode: yup
        .string()
        .nullable()
        .required(ValidationMessages.RENTAL_MODE),
      rate_conversion_method: yup
        .string()
        .nullable()
        .required(ValidationMessages.RATE_CONVERSION_METHOD),
      rv_balloon_applicable: yup
        .boolean()
        .nullable()
        .default(undefined)
        .test({
          test: function (value, context: any) {
            let rv_chart_id =
              context?.from[1]?.value?.financial_product_charts_association
                ?.rv_chart_id;
            return value === true && !rv_chart_id
              ? this.createError({
                  message: ValidationMessages.RV_CHART,
                  path: "financial_product_charts_association.rv_chart_id",
                })
              : true;
          },
        })
        .required(ValidationMessages.RV_BALLON_APPLICABLE),
      rv_balloon_type: yup
        .string()
        .nullable()
        .when("rv_balloon_applicable", {
          is: true,
          then: yup
            .string()
            .required(ValidationMessages.RV_BALLON_TYPE)
            .nullable(),
          otherwise: yup.string().nullable().notRequired(),
        }),
      rv_calculation_method: yup
        .string()
        .nullable()
        .when("rv_balloon_applicable", {
          is: true,
          then: yup
            .string()
            .required(ValidationMessages.RV_CALCULATION_METHOD)
            .nullable(),
          otherwise: yup.string().nullable().notRequired(),
        }),
      rv_balloon_override: yup
        .boolean()
        .nullable()
        .when("rv_balloon_applicable", {
          is: true,
          then: yup
            .boolean()
            .required(ValidationMessages.RV_BALLON_OVERRIDE)
            .nullable(),
          otherwise: yup.boolean().nullable().notRequired(),
        }),
    })
    .required(),
  financial_product_charts_association: yup
    .object()
    .shape({
      interest_chart_id: yup.mixed().when("non_asset_interest_chart_id", {
        is: (value) => value !== undefined && value !== null && value !== "",
        then: yup
          .object()
          .nullable()
          .shape({
            label: yup.string(),
            id: yup.string(),
          })
          .nullable(),
        otherwise: yup
          .object()
          .nullable()
          .shape({
            label: yup.string(),
            id: yup.string(),
          })
          .required("Margin Rate is required"),
      }),
      non_asset_interest_chart_id: yup.mixed(),
      // .test(
      //   "is-valid-object",
      //   ValidationMessages.INTEREST_CHART,
      //   function (value) {
      //     if (typeof value !== "object") {
      //       return false;
      //     }

      //     if (!value.id) {
      //       return false;
      //     }

      //     return true;
      //   }
      // )
      // .required(ValidationMessages.INTEREST_CHART),
      // .typeError(ValidationMessages.INTEREST_CHART),
      rv_chart_id: yup.object().nullable(),
    })
    .required(),
  program_commission: yup.object().shape({
    commission_type: yup.string().nullable(),
    // .required(ValidationMessages.COMMISSION_TYPE_REQUIRED),
    amortization_method: yup.string().nullable(),
    // .required(ValidationMessages.AMORTIZATION_METHOD_REQUIRED),
    commission_override: yup.string().nullable(),
    // .required(ValidationMessages.COMMISSION_OVERRIDE_REQUIRED),
  }),
  program_subsidy: yup.object().shape({
    calculation_method: yup.string().nullable(),
    // .required(ValidationMessages.CALCULATION_METHOD_REQUIRED),
    amortization_method: yup.string().nullable(),
    // .required(ValidationMessages.AMORTIZATION_METHOD_REQUIRED),
    tax_amortization: yup.string().nullable(),
    // .required(ValidationMessages.COMMISSION_OVERRIDE_REQUIRED),
    subsidy_override: yup.string().nullable(),
    // .required(ValidationMessages.SUBSIDY_OVERRIDE_REQUIRED),
  }),
  program_end_of_terms: yup.object().shape({
    purchase_option_applicable: yup
      .string()
      .nullable()
      .required(ValidationMessages.PURCHASE_OPTION_APPLICABLE_REQUIRED),
    purchase_option_type: yup
      .string()
      .nullable()
      .when("purchase_option_applicable", {
        is: (purchase_option_applicable) => purchase_option_applicable == "Yes",
        then: yup
          .string()
          .nullable()
          .required(ValidationMessages.PURCHASE_OPTION_TYPE_REQUIRED),
      }),
  }),

  // rv_chart_id: yup.string().when("$rv_balloon_applicable",{
  //     // is: (yup.ref("financial_product_financial_parameter.rv_balloon_applicable").getValue("financial_product_financial_parameter.rv_balloon_applicable")),
  //     is:(val) => {
  //       return val?.rv_balloon_applicable
  //     },
  //     then: yup.string().required(ValidationMessages.RV_CHART),
  //     otherwise: yup.string().nullable().notRequired(),
  //   }),
  // rv_chart_id: yup
  //   .string()
  //   .when({
  //     is: (yup.ref("$financial_product_financial_parameter.rv_balloon_applicable").getValue("$financial_product_financial_parameter.rv_balloon_applicable")),
  //     then: yup.string().required(ValidationMessages.RV_CHART),
  //     otherwise: yup.string().nullable().notRequired(),
  //   }),
});

const Program: FC = () => {
  const theme = useTheme();
  const { actions } = useStoreContext();
  const [failurePopupState, setFailurePopupState] = useState<boolean>(false);
  const [isErrorName, setIsErrorName] = useState<boolean>(false);
  const [isErrorCode, setIsErrorCode] = useState<boolean>(false);
  const [isDisabledRv, setIsDisabledRv] = useState<boolean>(false);

  let params = useParams();
  const [financialProductId, setFinancialProductId] = useState<number>();
  const form = useForm<any>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      financial_product_general: {
        validity_start_date: new Date().toISOString(),
        validity_end_date: null,
      },
      program_commission: {
        commission_type: CommissionType.CRITERIA,
      },
    },
  });
  const {
    control,
    getValues,
    formState: { errors, isDirty, isValid },
    resetField,
    trigger,
    setValue,
    reset,
    setError,
    clearErrors,
    watch,
  } = form;
  const location = useLocation();
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const { mutate: CheckNameDuplicate } =
    UseMutation_CheckDuplicateProgramName();
  const { mutate: CheckCodeDuplicate } =
    UseMutation_CheckDuplicateProgramCode();
  const { mutate: findProgram } = UseMutation_findProgramById();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [isHide, setIsHide] = useState<boolean>(false);
  const [fpData, setFpData] = useState<IFpGeneral>();
  const [errorName, setErrorMsgName] = useState(null);
  const [errorCode, setErrorMsgCode] = useState(null);
  const [errorDate, setErrorDate] = useState(null);
  const [hideSaveAsMenu, setHideSaveAsMenu] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [programById, setProgramById] = useState<any>();
  const [dealerIds, setDealerIds] = useState<Array<number>>([]);
  const [deletedAssetGroup, setDeletedAssetGroup] = useState<
    Array<IFpAssetGroup>
  >([]);
  UseQuery_getAllCharts();
  useEffect(() => {
    if (Number(params?.id)) {
      findProgram(Number(params?.id), {
        onSuccess: (response: any) => {
          setProgramById(response?.data);
        },
      });
    }
  }, []);
  const checkProductName = (data: any) => {
    CheckNameDuplicate(data, {
      onSuccess: (response) => {
        setIsErrorName(response.data);
        if (response.data) {
          setErrorMsgName(ValidationMessages.UNIQUE_NAME);
        } else {
          setErrorMsgName("");
          clearErrors("financial_product_general.name");
        }
      },
    });
  };
  const checkProductCode = (data: any) => {
    CheckCodeDuplicate(data, {
      onSuccess: (response) => {
        setIsErrorCode(response.data);
        if (response.data) {
          setErrorMsgCode(ValidationMessages.UNIQUE_CODE);
        } else {
          setErrorMsgCode("");
          clearErrors("financial_product_general.financial_product_code");
        }
      },
    });
  };
  const debouncedSetName = useCallback(
    debounce((nextValue: string) => checkProductName(nextValue), 800),
    [checkProductName]
  );
  const debouncedSetCode = useCallback(
    debounce((nextValue: string) => checkProductCode(nextValue), 800),
    [checkProductCode]
  );
  const onProductNameChange = (value: string) => {
    setValue("financial_product_general.name", value);
    debouncedSetName(value);
  };
  const onProductCodeChange = (value: string) => {
    setValue("financial_product_general.financial_product_code", value);
    debouncedSetCode(value);
  };
  const tabs = [
    {
      title: (
        <Box
          className={
            Object.keys(errors).includes("financial_product_general") ||
            errorName ||
            errorCode
              ? "error"
              : ""
          }
          theme={theme}
        >
          General
        </Box>
      ),
      content: (
        <ProgramGeneral
          onProductNameChange={onProductNameChange}
          onProductCodeChange={onProductCodeChange}
          control={control}
          setValue={setValue}
          actionType={actionType}
          getValues={getValues}
          errors={errors}
          setErrorMsgName={setErrorMsgName}
          setErrorMsgCode={setErrorMsgCode}
          errorName={errorName}
          errorCode={errorCode}
          errorDate={errorDate}
          setErrorDate={setErrorDate}
          clearErrors={clearErrors}
        />
      ),
    },
    {
      title: (
        <Box
          className={
            Object.keys(errors).includes(
              "financial_product_financial_parameter"
            )
              ? "error"
              : ""
          }
          theme={theme}
        >
          Financial Parameters
        </Box>
      ),
      content: (
        <FinancialParameter
          setValue={setValue}
          control={control}
          actionType={actionType}
          getValues={getValues}
          isDisabledRv={isDisabledRv}
          setIsDisabledRv={setIsDisabledRv}
          reset={resetField}
          errors={errors}
          clearErrors={clearErrors}
        />
      ),
    },
    {
      title: (
        <Box
          className={
            Object.keys(errors).includes("program_subsidy") ? "error" : ""
          }
          theme={theme}
        >
          Subsidy
        </Box>
      ),
      content: (
        <ProgramSubsidy form={form} isDisabled={isDisabled}></ProgramSubsidy>
      ),
    },
    {
      title: (
        <Box
          className={
            Object.keys(errors).includes("program_commission") ? "error" : ""
          }
          theme={theme}
        >
          Commission
        </Box>
      ),
      content: <ProgramCommission form={form} isDisabled={isDisabled} />,
    },
    {
      title: (
        <Box
          className={
            Object.keys(errors).includes("program_end_of_terms") ? "error" : ""
          }
          theme={theme}
        >
          End of Term
        </Box>
      ),
      content: <ProgramEndOfTerm form={form} isDisabled={isDisabled} />,
    },
    {
      title: (
        <Box className="" theme={theme}>
          Dealers
        </Box>
      ),
      content: (
        <Dealers
          control={control}
          setDealerIds={setDealerIds}
          dealerIds={dealerIds}
          actionType={actionType}
          setValue={setValue}
        />
      ),
    },
    {
      title: (
        <Box className="" theme={theme}>
          Assets
        </Box>
      ),
      content: (
        <AssetGroupTable
          control={control}
          setValue={setValue}
          actionType={actionType}
          setDeletedAssetGroup={setDeletedAssetGroup}
          deletedAssetGroup={deletedAssetGroup}
        />
      ),
    },
    {
      title: (
        <Box
          className={
            Object.keys(errors).includes("financial_product_charts_association")
              ? "error"
              : ""
          }
          theme={theme}
        >
          Charts Association
        </Box>
      ),
      content: (
        <MetricsAssociation
          actionType={actionType}
          control={control}
          watch={watch}
          errors={errors}
        />
      ),
    },
  ];
  const { mutate: AddFp } = UseMutation_AddProgram();
  const { mutate: UpdateFp } = UseMutation_UpdateProgram();
  const onClose = () => {
    setFailurePopupState(false);
  };

  const [state, dispatch] = useSetupsStore();
  const { fpAssetGroup } = state;
  const queryClient = useQueryClient();
  const lenderProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const charts: Array<ICharts> = queryClient.getQueryData(
    QueryKeys.GET_ALL_CHARTS
  );
  const setChartMetricAsco = (
    interestId,
    rvChartId,
    commissionId,
    subsidyId,
    downPaymentId,
    baseChartId
  ) => {
    let chartsAsoc: Array<FpChartsAssociation> =
      new Array<FpChartsAssociation>();

    const interestChart: any = fpData?.chart_associations?.filter(
      (x) => x.chart_type_id == ChartTypes.InterestChart
    );

    const rvChart = fpData?.chart_associations?.find(
      (x) => x.chart_type_id == ChartTypes.RvChart
    );
    const commissionChart = fpData?.chart_associations?.find(
      (x) => x.chart_type_id == ChartTypes.CommissionChart
    );
    const subsidyChart = fpData?.chart_associations?.find(
      (x) => x.chart_type_id == ChartTypes.SubsidyChart
    );
    const downPaymentChart = fpData?.chart_associations?.find(
      (x) => x.chart_type_id == ChartTypes.DownPaymentChart
    );
    const baseChart: any = fpData?.chart_associations?.find(
      (x) => x.chart_type_id == ChartTypes.BaseRateChart
    );
    const interest: FpChartsAssociation = {
      chart_id: interestId > 0 ? Number(interestId) : null,
      chart_type_id: ChartTypes.InterestChart,
      id: interestChart?.[0]?.id ? interestChart?.[0]?.id : undefined,
    };
    chartsAsoc.push(interest);

    const commission: FpChartsAssociation = {
      chart_id: commissionId > 0 ? Number(commissionId) : null,
      chart_type_id: ChartTypes.CommissionChart,
      id: commissionChart?.id ? commissionChart?.id : undefined,
    };
    chartsAsoc.push(commission);
    const subsidy: FpChartsAssociation = {
      chart_id: subsidyId > 0 ? Number(subsidyId) : null,
      chart_type_id: ChartTypes.SubsidyChart,
      id: subsidyChart?.id ? subsidyChart?.id : undefined,
    };
    chartsAsoc.push(subsidy);
    const downPayment: FpChartsAssociation = {
      chart_id: downPaymentId > 0 ? Number(downPaymentId) : null,
      chart_type_id: ChartTypes.DownPaymentChart,
      id: downPaymentChart?.id ? downPaymentChart?.id : undefined,
    };
    chartsAsoc.push(downPayment);
    const baseRate: FpChartsAssociation = {
      chart_id: baseChartId > 0 ? Number(baseChartId) : null,
      chart_type_id: ChartTypes.BaseRateChart,
      id: baseChart?.id ? baseChart?.id : undefined,
    };
    chartsAsoc.push(baseRate);
    if (
      rvChartId > 0 ||
      (actionType == ActionOptions.EDIT && rvChart?.chart_id > 0)
    ) {
      const rv: FpChartsAssociation = {
        chart_id: rvChartId > 0 ? Number(rvChartId) : null,
        chart_type_id: ChartTypes.RvChart,
        id: rvChart?.id ? rvChart?.id : undefined,
      };
      chartsAsoc.push(rv);
    }
    return chartsAsoc;
  };

  const updateFp = (payload) => {
    let fpId: number;
    let fpParameterId;
    let InterestId;
    payload.financial_product_general.financial_parameters = undefined;
    payload.financial_product_general.asset_groups = undefined;
    payload.financial_product_general.charts_associations = undefined;

    let rvId;
    if (fpData != undefined) {
      /// get primary keys ids
      fpId = fpData.id;
      fpParameterId = Number(fpData?.financial_parameters[0]?.id);
      if (fpData?.chart_associations?.length > 0) {
        InterestId = Number(
          fpData?.chart_associations?.find(
            (x) => x.chart_type_id == ChartTypes.InterestChart
          )?.id
        );
        rvId = Number(
          fpData?.chart_associations?.find(
            (x) => x.chart_type_id == ChartTypes.RvChart
          )?.id
        );
      }
    }
    if (payload?.financial_product_general != undefined) {
      payload.financial_product_general.id = fpId;
    } else {
      payload.financial_product_general = fpData;
    }
    if (payload?.financial_product_financial_parameter != undefined) {
      payload.financial_product_financial_parameter.financial_product_id =
        undefined;
      payload.financial_product_financial_parameter.id = Number(
        fpData?.financial_parameters[0].id
      );
    } else {
      payload.financial_product_financial_parameter =
        fpData.financial_parameters[0];
      payload.financial_product_financial_parameter.financial_product_id =
        undefined;
    }

    payload.financial_product_asset_group = fpAssetGroup;
    payload?.financial_product_asset_group?.map((x) => {
      x.financial_product_id = undefined;
    });
    payload.financial_product_deleted_asset = deletedAssetGroup;
    UpdateFp(payload, {
      onSuccess: (data) => {
        setProgramById(data?.data);
        reset(getValues(), { keepErrors: true });
        if (
          payload?.financial_product_general?.program_state ==
          ProgramState.Draft.toString()
        ) {
          setHideSaveAsMenu(true);
          actions.setToast({
            toastMessage: ToastMessages.SAVE_AS_DRAFT,
            toastState: true,
            showIcon: true,
          });
        } else {
          setHideSaveAsMenu(false);
          actions.setToast({
            toastMessage: ToastMessages.Record_Updated_Success,
            toastState: true,
          });
        }
        if (location?.state?.toString() == ActionOptions.SAVE_AS) {
          location.state = null;
          setActionType(ActionOptions.SAVE_AS);
        }
        setFpData(data?.data);
        dispatch(removeFpAssetGroup([]));
        data?.data?.asset_groups?.map((item) => {
          dispatch(addFpAssetGroup(item));
        });
        setDeletedAssetGroup([]);
      },
    });
  };
  function onSave(data, program_state: string) {
    resetField("dealerGroup");
    resetField("assetGroup");
    let fp: IProgram = data;
    fp.financial_product_general = {
      program_state: program_state,
      is_active:
        program_state == ProgramState.Draft.toString() ||
        actionType == ActionOptions.SAVE_AS
          ? false
          : data.financial_product_general?.id > 0
          ? data.financial_product_general?.is_active
          : true,
      id: data.financial_product_general?.id
        ? data?.financial_product_general?.id
        : null,
      lender_id: data.financial_product_general?.lender_id ?? null,
      name: data.financial_product_general?.name
        ? data?.financial_product_general?.name
        : null,
      financial_product_code: data?.financial_product_general
        ?.financial_product_code
        ? data?.financial_product_general?.financial_product_code
        : null,
      finance_type: data.financial_product_general?.finance_type
        ? data?.financial_product_general?.finance_type
        : null,
      dealers: dealerIds ? dealerIds : [],
      validity_start_date: data?.financial_product_general?.validity_start_date,
      validity_end_date: data?.financial_product_general?.validity_end_date,
    };
    fp.financial_product_asset_group = fpAssetGroup;
    fp.financial_product_financial_parameter = {
      id: data?.financial_product_financial_parameter?.id
        ? data?.financial_product_financial_parameter?.id
        : null,
      financial_product_id: data?.financial_product_financial_parameter
        ?.financial_product_id
        ? data?.financial_product_financial_parameter?.financial_product_id
        : null,
      rental_frequency: data?.financial_product_financial_parameter
        ?.rental_frequency
        ? data?.financial_product_financial_parameter?.rental_frequency
        : null,
      interest_type: data?.financial_product_financial_parameter?.interest_type
        ? data?.financial_product_financial_parameter?.interest_type
        : null,
      base_rate_source: data?.financial_product_financial_parameter
        ?.base_rate_source
        ? data?.financial_product_financial_parameter?.base_rate_source
        : null,
      interest_rate_override:
        data?.financial_product_financial_parameter?.interest_rate_override !==
        null
          ? data?.financial_product_financial_parameter?.interest_rate_override
          : null,
      calculation_method: data?.financial_product_financial_parameter
        ?.calculation_method
        ? data?.financial_product_financial_parameter?.calculation_method
        : null,
      rental_mode: data?.financial_product_financial_parameter?.rental_mode
        ? data?.financial_product_financial_parameter?.rental_mode
        : null,
      rate_conversion_method: data?.financial_product_financial_parameter
        ?.rate_conversion_method
        ? data?.financial_product_financial_parameter?.rate_conversion_method
        : null,
      rv_balloon_applicable:
        data?.financial_product_financial_parameter?.rv_balloon_applicable !==
        null
          ? data?.financial_product_financial_parameter?.rv_balloon_applicable
          : null,
      rv_balloon_type: data?.financial_product_financial_parameter
        ?.rv_balloon_type
        ? data?.financial_product_financial_parameter?.rv_balloon_type
        : null,
      rv_calculation_method: data?.financial_product_financial_parameter
        ?.rv_calculation_method
        ? data?.financial_product_financial_parameter?.rv_calculation_method
        : null,
      rv_balloon_override:
        data?.financial_product_financial_parameter?.rv_balloon_override !==
        null
          ? data?.financial_product_financial_parameter?.rv_balloon_override
          : null,
    };
    fp.financial_product_charts_association = setChartMetricAsco(
      data?.financial_product_charts_association?.interest_chart_id?.id,
      data?.financial_product_charts_association?.rv_chart_id?.id,
      data?.financial_product_charts_association?.program_commission?.id,
      data?.financial_product_charts_association?.program_subsidy?.id,
      data?.financial_product_charts_association?.down_payment?.id,
      data?.financial_product_charts_association?.base_rate_chart?.id
    );
    fp.program_commission = {
      id: data?.program_commission?.id ?? null,
      commission_type: data?.program_commission?.commission_type ?? null,
      amortization_method:
        data?.program_commission?.amortization_method ?? null,
      commission_override:
        data?.program_commission?.commission_override ?? null,
    };
    fp.program_subsidy = {
      id: data?.program_subsidy?.id ?? null,
      calculation_method: data?.program_subsidy?.calculation_method ?? null,
      amortization_method: data?.program_subsidy?.amortization_method ?? null,
      tax_amortization: data?.program_subsidy?.tax_amortization ?? null,
      subsidy_override: data?.program_subsidy?.subsidy_override ?? null,
    };
    fp.program_end_of_terms = {
      id: data?.program_end_of_terms?.id ?? null,
      purchase_option_applicable:
        data?.program_end_of_terms?.purchase_option_applicable ?? null,
      purchase_option_type:
        data?.program_end_of_terms?.purchase_option_type ?? null,
    };
    if (fpData) {
      updateFp(fp);
    } else {
      if (isErrorName == false && isErrorCode == false) {
        AddFp(fp, {
          onSuccess: (data) => {
            reset(getValues(), { keepErrors: true });
            setValue(
              "program_commission.id",
              data?.data?.program_commission?.id
            );
            setValue("program_subsidy.id", data?.data?.program_subsidy?.id);
            setValue(
              "program_end_of_terms.id",
              data?.data?.program_end_of_terms?.id
            );
            if (data?.data?.program_state == ProgramState.Draft.toString()) {
              setHideSaveAsMenu(true);
              setActionType(ActionOptions.SAVE_AS_DRAFT);
              actions.setToast({
                toastMessage: ToastMessages.SAVE_AS_DRAFT,
                toastState: true,
                showIcon: true,
              });
            } else {
              setHideSaveAsMenu(false);
              actions.setToast({
                toastMessage: ToastMessages.Record_Added_Success,
                toastState: true,
              });
            }
            navigate(APP_ROUTES.Program + "/" + data?.data?.id, {
              state: null,
            });
            setFpData(data?.data);
            data?.data?.FpAssetGroups?.map((item) => {
              dispatch(addFpAssetGroup(item));
            });
            setFinancialProductId(data?.data?.id);
            setActionType(ActionOptions.EDIT);
          },
        });
      } else if (isErrorName == true && isErrorCode == false) {
        setError("financial_product_general.name", {
          message: ValidationMessages.UNIQUE_NAME,
        });
      } else if (isErrorCode == true && isErrorName == false) {
        setError("financial_product_general.financial_product_code", {
          message: ValidationMessages.UNIQUE_CODE,
        });
      } else if (isErrorName && isErrorCode) {
        setError("financial_product_general.name", {
          message: ValidationMessages.UNIQUE_NAME,
        });
        setError("financial_product_general.code", {
          message: ValidationMessages.UNIQUE_CODE,
        });
      }
    }
  }
  function setProgramCommission(data: IProgramCommission) {
    setValue("program_commission", {
      id: data?.id,
      commission_type: data?.commission_type ?? "",
      amortization_method: data?.amortization_method ?? "",
      commission_override: data?.commission_override ?? "",
    });
  }
  function setProgramSubsidy(data: IProgramSubsidy) {
    setValue("program_subsidy", {
      id: data?.id,
      calculation_method: data?.calculation_method ?? "",
      amortization_method: data?.amortization_method ?? "",
      tax_amortization: data?.tax_amortization ?? "",
      subsidy_override: data?.subsidy_override ?? "",
    });
  }
  function setProgramEndofTerm(data: IProgramEndOfTerm) {
    setValue("program_end_of_terms", {
      id: data?.id,
      purchase_option_applicable: data?.purchase_option_applicable ?? "",
      purchase_option_type: data?.purchase_option_type ?? "",
    });
  }
  function setProgramData(fp: any) {
    let arr: Array<number> = [];
    fp?.dealers?.map((x) => {
      arr.push(x.id);
    });
    setDealerIds(arr);
    let fpGeneral: IFpGeneral = fp;
    setValue("financial_product_general", fpGeneral);
    setValue(
      "financial_product_financial_parameter",
      fp?.financial_parameters[0]
    );

    let interestChart = fp?.chart_associations?.find(
      (x) => x.chart_type_id == ChartTypes.InterestChart
    );
    let interestChartName = charts?.find(
      (x) => x.id == interestChart?.chart_id
    )?.chart_name;
    setValue("financial_product_charts_association.interest_chart_id", {
      label: interestChartName ?? "",
      id: interestChart?.chart_id ?? "",
    });
    let rvChart = fp?.chart_associations?.find(
      (x) => x.chart_type_id == ChartTypes.RvChart
    );
    let rvChartName = charts?.find(
      (x) => x.id == rvChart?.chart_id
    )?.chart_name;
    const subsidyChart = charts?.find(
      (x) =>
        x.id ==
        fp?.chart_associations?.find(
          (x) => x.chart_type_id == ChartTypes.SubsidyChart
        )?.chart_id
    );
    const commissionChart = charts?.find(
      (x) =>
        x.id ==
        fp?.chart_associations?.find(
          (x) => x.chart_type_id == ChartTypes.CommissionChart
        )?.chart_id
    );
    const downPaymentChart = charts?.find(
      (x) =>
        x.id ==
        fp?.chart_associations?.find(
          (x) => x.chart_type_id == ChartTypes.DownPaymentChart
        )?.chart_id
    );
    let baseRateChart = fp?.chart_associations?.find(
      (x) => x.chart_type_id == ChartTypes.BaseRateChart
    );
    setValue("financial_product_charts_association.base_rate_chart", {
      label: baseRateChart?.chart_id ? "Base Rate" : "",
      id: baseRateChart?.chart_id ? baseRateChart?.chart_id : "",
    });
    setValue("financial_product_charts_association.program_subsidy", {
      label: subsidyChart?.chart_name ?? "",
      id: subsidyChart?.id ?? "",
    });
    setValue("financial_product_charts_association.program_commission", {
      label: commissionChart?.chart_name ?? "",
      id: commissionChart?.id ?? "",
    });
    setValue("financial_product_charts_association.down_payment", {
      label: downPaymentChart?.chart_name ?? "",
      id: downPaymentChart?.id ?? "",
    });
    setProgramCommission(fp?.program_commission);
    setProgramSubsidy(fp?.program_subsidy);
    setProgramEndofTerm(fp?.program_end_of_terms);
    setValue("financial_product_charts_association.rv_chart_id", {
      label: rvChartName ? rvChartName : "",
      id: rvChart?.chart_id ? rvChart?.chart_id : "",
    });

    if (!(fpAssetGroup?.length > 0)) {
      fp.asset_groups?.map((item) => {
        dispatch(addFpAssetGroup(item));
      });
    }
  }
  function handleSaveAsData(fp: any) {
    setFpData(null);
    fp.name = "";
    fp.financial_product_code = "";
    fp.id = null;
    fp.financial_parameters[0].id = null;
    fp.program_subsidy.id = null;
    fp.program_commission.id = null;
    fp.program_end_of_terms.id = null;

    for (let i = 0; i < fp.chart_associations.length; i++) {
      fp.chart_associations[i].id = null;
    }
  }
  useEffect(() => {
    const fpId = Number(params?.id);
    if (actionType == ActionOptions.ADD && !fpData?.id && !fpId) {
      setHideSaveAsMenu(true);
    }
    if (!programById) return;
    if (fpId > 0 && fpData == undefined) {
      const fp = programById;
      if (
        actionType === ActionOptions.SAVE_AS_DRAFT ||
        location?.state?.toString() == ActionOptions.SAVE_AS_DRAFT
      ) {
        setHideSaveAsMenu(true);
        setFpData(fp);
      }
      if (location?.state?.toString() == ActionOptions.EDIT) {
        setActionType(ActionOptions.EDIT);
        setFpData(fp);
      } else if (location?.state?.toString() == ActionOptions.VIEW) {
        setActionType(ActionOptions.VIEW);
        setIsDisabled(true);
        setIsHide(true);
        setFpData(fp);
      } else if (location?.state?.toString() == ActionOptions.SAVE_AS) {
        setHideSaveAsMenu(true);
        setActionType(ActionOptions.SAVE_AS);
        handleSaveAsData(fp);
      }
      if (fp != undefined) {
        setProgramData(fp);
      }
    }
  }, [programById]);

  const handleSelection = (event, key, value) => {
    if (key == ActionOptions.SAVE_AS) {
      setHideSaveAsMenu(true);
      setActionType(ActionOptions.SAVE_AS);
      handleSaveAsData(programById);
      setProgramData(programById);
      return navigate(APP_ROUTES.Program + "/" + value.id, {
        state: ActionOptions.SAVE_AS,
      });
    }
  };
  const [tableOptions, setTableOptions] = useState([
    {
      optionText: (
        <>
          <Icon className="menu-icon" name="EditIcon" />
          {ActionOptions.SAVE_AS}
        </>
      ),
      optionkey: ActionOptions.SAVE_AS,
      optionValue: {},
    },
  ]);
  useEffect(() => {
    if (hideSaveAsMenu) {
      setTableOptions(
        tableOptions.filter((x) => x.optionkey !== ActionOptions.SAVE_AS)
      );
    } else {
      let SaveAs = tableOptions.find(
        (x) => x.optionkey === ActionOptions.SAVE_AS
      );
      if (!SaveAs)
        setTableOptions((prev) => [
          ...prev,
          {
            optionText: (
              <>
                <Icon className="menu-icon" name="EditIcon" />
                {ActionOptions.SAVE_AS}
              </>
            ),
            optionkey: ActionOptions.SAVE_AS,
            optionValue: {},
          },
        ]);
    }
  }, [hideSaveAsMenu]);

  function beforeSubmit() {
    if (errorName || errorCode || errorDate) {
      return;
    } else if (Object.keys(errors)?.length !== 0) {
      onSave(getValues(), ProgramState.Draft.toString());
    } else if (
      Object.keys(errors)?.length === 0 &&
      (getValues("financial_product_general.program_state")?.toString() ===
        "Draft" ||
        getValues("financial_product_general.program_state")?.toString() ===
          undefined ||
        actionType == ActionOptions.SAVE_AS)
    ) {
      onSave(getValues(), ProgramState.ReadytoPublish.toString());
    } else {
      onSave(
        getValues(),
        getValues("financial_product_general.program_state").toString()
      );
    }
  }
  const onSaveConfirm = (e: any) => {
    trigger([
      "financial_product_general",
      "financial_product_financial_parameter",
      "financial_product_charts_association",
      "program_commission",
      "program_subsidy",
      "program_end_of_terms",
    ]);
    beforeSubmit();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    reset(getValues());
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <LayoutWithSideNav theme={theme}>
        <PageHeader theme={theme} className="main-page-header border-bottom">
          <Grid theme={theme} container spacing={2}>
            <Grid theme={theme} item xs={12} sm={12} lg={7} md={6}>
              <Box theme={theme} display="flex">
                <Button
                  className="back-btn"
                  defaultBtn
                  theme={theme}
                  iconText={<Icon name="IconLeftArrow" />}
                  onClick={() => {
                    navigate(APP_ROUTES.Programs);
                  }}
                />
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{ fontSize: 36 }}
                  rowGap={5}
                  mt={1}
                  data-testid="heading"
                  theme={theme}
                >
                  Programs
                </Typography>
              </Box>
            </Grid>
            {!isHide ? (
              <Grid
                theme={theme}
                item
                xs={12}
                lg={5}
                sm={12}
                md={6}
                textAlign="right"
                className="action-area"
              >
                {hideSaveAsMenu ? null : (
                  <Menu
                    theme={theme}
                    disablePortal
                    options={tableOptions.map((option) => {
                      return {
                        optionText: option.optionText,
                        optionkey: option.optionkey,
                        optionValue: fpData,
                      };
                    })}
                    handleOptionClick={handleSelection}
                    render={(onMenuSelection) => (
                      <Button
                        theme={theme}
                        secondary
                        iconText={<Icon name="MoreIcon" />}
                        id="programSaveAs"
                        onClick={onMenuSelection}
                        sx={{ mr: 16 }}
                      />
                    )}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  />
                )}
                {isDirty ? (
                  <Button
                    theme={theme}
                    id="assetSave"
                    onClick={async () => {
                      await trigger([
                        "financial_product_general",
                        "financial_product_financial_parameter",
                        "financial_product_charts_association",
                        "program_commission",
                        "program_subsidy",
                        "program_end_of_terms",
                      ]);
                      beforeSubmit();
                    }}
                    //onClick={handleSubmit(onSave)}
                    primary
                    text="Save Changes"
                  />
                ) : null}
              </Grid>
            ) : null}
          </Grid>
        </PageHeader>
        {/* <PageHeader theme={theme} container item spacing={2}>
          <Grid theme={theme} item xs={12} sm={6} lg={9} md={6}>
            <Typography
              variant="h3"
              component="h3"
              sx={{ fontSize: 36 }}
              rowGap={5}
              mt={1}
              data-testid="heading"
              theme={theme}
            >
              Program
            </Typography>
          </Grid>
          <Grid
            theme={theme}
            item
            xs={12}
            lg={3}
            sm={6}
            md={6}
            textAlign="right"
          >
            <Box theme={theme} display={"flex"} justifyContent={"flex-end"}>
              <Menu
                theme={theme}
                options={tableOptions.map((option) => {
                  return {
                    optionText: option.optionText,
                    optionkey: option.optionkey,
                    optionValue: fpData,
                  };
                })}
                handleOptionClick={handleSelection}
                render={(onMenuSelection) => (
                  <CustomButton
                    secondary
                    iconText={<Icon name="MoreIcon" />}
                    onClick={onMenuSelection}
                    sx={{ marginRight: 16 }}
                  ></CustomButton>
                )}
              />

              {!isHide ? (
                <Button
                  className="right-button"
                  onClick={handleSubmit(onSave)}
                  primary
                  text="Save Changes"
                />
              ) : null}
            </Box>
          </Grid>
        </PageHeader>
        <Grid theme={theme} item xs={12}>
          <Divider />
        </Grid> */}
        <form onSubmit={(e) => e.preventDefault()}>
          <DuoTabWrap theme={theme} className="tab-form">
            <DuoTab
              theme={theme}
              varient={"underline"}
              items={tabs}
              defaultTabIndex={0}
            />
          </DuoTabWrap>
        </form>
        {failurePopupState && (
          <Dialog
            size="xs"
            variant="confirmation"
            open={failurePopupState}
            noFooter={false}
            onCloseDialog={onClose}
            theme={theme}
            customFooter={
              <Button
                primary
                text={"Close"}
                fullWidth={true}
                style={{ paddingRight: "82px", paddingLeft: "82px" }}
                onClick={onClose}
              />
            }
          >
            <Box theme={theme} className="dialog-icon">
              <Icon name="DialogAlertIcon" />
            </Box>
            <Box theme={theme}>
              <Typography
                theme={theme}
                mb={2}
                className="content-title"
                variant="h2"
                component="h2"
              >
                An Error Ocurred
              </Typography>
              <Typography
                theme={theme}
                className="text-muted"
                variant="body2"
                component="span"
              >
                Something went wrong
              </Typography>
            </Box>
          </Dialog>
        )}
      </LayoutWithSideNav>
    </>
  );
};

export default Program;
