export enum DialogMessages {
  confirmationTitle = "Are you sure you want to delete?",
  updateTitle = "Are you sure you want to update?",
  confirmationText = "Once deleted, you cannot undo this action.",
  deleteBtnText = "Delete",
  updateBtnText = "Update",
  saveBtnText = "Save",
  saveTitle = "Do you want to leave this page?",
  saveText = " You have unsaved changes. Would you like to save them before exiting?",
  uploadTitle = "Are you sure you want to upload?",
  uploadWarningText = "The file you are uploading will override the existing records. Are you sure you want to continue?",
  uploadBtnText = "Upload",
}
