import React, { FC, useEffect, useState } from "react";
import { TablePagination, useTheme } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Icon, Menu, DataTable, Button, Box, Input } from "@ntpkunity/controls";
import { PaginationWrap, DataTableWrap, Switch } from "@sharedComponents";
import {
  UseQuery_getAllLenderIntegration_filter,
  UseQuery_getLenderIntegrations,
} from "services";
import { useStoreContext } from "Stores/SetupStore/Store";
import TBIntegrationRow from "./TBIntegrationRow";
import ChevronDown from "../../shared/assets/images/chevron-down";

const IntergrationTable: FC<{
  onEdit: (data) => unknown;
  onDelete: (data) => unknown;
  onProviderMapping: (id) => unknown
  setParentIntegrationState: any;
}> = ({ onEdit, onDelete, setParentIntegrationState, onProviderMapping}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}`
  );

  const { data: integrationsData } =
    UseQuery_getAllLenderIntegration_filter(columnFilters);

  const [codeFilter, setCodeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}`;
    if (codeFilter.trimStart() != null && codeFilter.trimStart() != "") {
      query_string = query_string.concat(`&integration=${codeFilter}`);
    }
    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled".startsWith(
          statusFilter.toLowerCase().slice(0, Math.max("enabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${true}`);
      } else if (
        "disabled".startsWith(
          statusFilter
            .toLowerCase()
            .slice(0, Math.max("disabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${false}`);
      } else {
        query_string = query_string.concat(`&is_active=None`);
      }
    }
    return query_string;
  };

  useEffect(() => {
    const result = integrationsData?.result?.reduce(function (r, a) {
      r[a.integration_type] = r[a.integration_type] || [];
      r[a.integration_type].push(a);
      return r;
    }, Object.create(null));
  }, [integrationsData]);

  const theme = useTheme();
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };
  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  return (
    <>
      <DataTableWrap className="table-tabs">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <DataTable
          theme={theme}
          variant="basic"
          theadChildren={
            <>
              <TableRow>
                <TableCell colSpan={2}>Integrations</TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
              <TableRow className="filters-row">
                <TableCell colSpan={2}>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setCodeFilter(value);
                      }}
                      onBlur={() => {
                        handleFilter();
                      }}
                      value={codeFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {integrationsData &&
                integrationsData?.result?.map((integrationRow, index) => {
                  return (
                    <React.Fragment key={integrationRow.id + "parent"}>
                      <TBIntegrationRow
                        onEdit={(data) => onEdit(data)}
                        onDelete={(data) => onDelete(data)}
                        onProviderMapping={(id) => onProviderMapping(id)}
                        integrationRow={integrationRow}
                        setParentIntegrationState={setParentIntegrationState}
                      />
                    </React.Fragment>
                  );
                })}
            </>
          }
        />
        <PaginationWrap>
          {integrationsData && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20]}
              component="div"
              count={integrationsData ? integrationsData?.total_results : -1}
              rowsPerPage={pageSize}
              page={pageNumber}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              SelectProps={{
                IconComponent: ChevronDown,
                MenuProps: { disablePortal: true },
              }}
            />
          )}
        </PaginationWrap>
      </DataTableWrap>
    </>
  );
};
export default IntergrationTable;
