import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Dialog, Box, IBoxProps, DuoTab, Button } from "@ntpkunity/controls";
import { styled } from "@mui/material/styles";
import FinancialInsuranceTable from "./financialInsuranceTable";
import OptionsTable from "./optionsTable";
import FeesTable from "./feesTable";
import { IProviderMappingProps, IFinanceTypeModel, IStates } from "Interface";
import { useForm } from "react-hook-form";
import {
  UseQuery_GetAllFinanceTypes,
  UseMutation_AddFeesMappings,
  UseMutation_AddOptionMappings,
  UseMutation_AddFPCategoryMappings,
  useMutation_GetAllOptionMappings,
  useMutation_GetFPCategoryMappings,
  useMutation_GetAllFeesMappings,
} from "services";
import { QueryKeys, ToastMessages } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import { useQueryClient } from "react-query";
import { UseQuery_getAllStates } from "services/dealer-configurations.service";

const DuoTabWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.duo-tab-wrap": {
    "&.align-center": {
      ".TabsUnstyled-root": {
        ".TabsListUnstyled-root": {
          justifyContent: "center",
          margin: "0 auto 24px auto",
          width: "max-content",
          backgroundColor: "#f2f2f2",
          ".TabUnstyled-root": {
            width: "auto",
          },
        },
      },
    },
    "&.sm": {
      ".TabsUnstyled-root": {
        ".TabsListUnstyled-root": {
          ".TabUnstyled-root": {
            padding: "4px 12px 4px 12px",
            "&.Mui-selected": {
              fontWeight: theme.typography.fontWeightMedium,
            },
          },
        },
      },
    },
  },
}));

const ProviderMapping: FC<IProviderMappingProps> = ({ providerId, setProviderMappingState }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  const [mappingDialog, setMappingDialog] = useState(true);

  const [updatedFeesRows, setUpdatedFeesRows] = useState(new Set());
  const [updatedOptionRows, setUpdatedOptionRows] = useState(new Set());
  const [updatedFPCategoryRows, setUpdatedFPCategoryRows] = useState(new Set());

  const form = useForm();
  const { handleSubmit } = form;

  const handleMappingClose = () => {
    setMappingDialog(false);
    setProviderMappingState(false);
  };

  UseQuery_GetAllFinanceTypes();
  UseQuery_getAllStates();

  const { mutate: AddFeesMappings } = UseMutation_AddFeesMappings();
  const { mutate: AddOptionMappings } = UseMutation_AddOptionMappings();
  const { mutate: AddFPCategoryMappings } = UseMutation_AddFPCategoryMappings();

  const { mutate: fetchFPCategoryMappings, data: fpCategoryMappings } =
    useMutation_GetFPCategoryMappings(providerId);
  const { mutate: fetchOptionMappings, data: optionMappings } =
    useMutation_GetAllOptionMappings(providerId);
  const { mutate: fetchFeesMappings, data: feesMappings } =
    useMutation_GetAllFeesMappings(providerId);

  useEffect(() => {
    fetchFPCategoryMappings();
  }, [fetchFPCategoryMappings, providerId]);

  useEffect(() => {
    fetchOptionMappings();
  }, [fetchOptionMappings, providerId]);

  useEffect(() => {
    fetchFeesMappings();
  }, [fetchFeesMappings, providerId]);

  const financeTypes: Array<IFinanceTypeModel> = queryClient.getQueryData(
    QueryKeys.GET_ALL_FINANCE_TYPES
  );
  const statesData: Array<IStates> = queryClient.getQueryData(
    QueryKeys.GET_ALL_STATES
  );

  const onSubmit = (data) => {
    const modifiedFeesMappingRows = Array.from(updatedFeesRows)?.map(
      (index: any) => {
        return {
          ...data.feesMapping[index],
          provider_id: providerId,
        };
      }
    );
    const modifiedOptionMappingRows = Array.from(updatedOptionRows)?.map(
      (index: any) => {
        return {
          ...data.optionMapping[index],
          provider_id: providerId,
        };
      }
    );
    const modifiedFPMappingRows = Array.from(updatedFPCategoryRows)?.map(
      (index: any) => {
        const category = data.fpMapping[index].category;

        const matchingCategory = fpCategoryMappings?.data?.find(
          (item) => item.category === category
        );
        return {
          ...data.fpMapping[index],
          provider_id: providerId,
          id: matchingCategory ? matchingCategory.id : null,
        };
      }
    );
    if (modifiedFeesMappingRows.length > 0) {
      AddFeesMappings(modifiedFeesMappingRows, {
        onSuccess: () => {
          actions.setToast({
            toastMessage: ToastMessages.Record_Updated_Success,
            toastState: true,
          });
        },
        onError: (error) => {
          actions.setToast({
            toastMessage: error?.response?.data?.detail?.message,
            toastState: true,
            variant: "error",
          });
        },
      });
    }
    if (modifiedOptionMappingRows.length > 0) {
      AddOptionMappings(modifiedOptionMappingRows, {
        onSuccess: () => {
          actions.setToast({
            toastMessage: ToastMessages.Record_Updated_Success,
            toastState: true,
          });
        },
        onError: (error) => {
          actions.setToast({
            toastMessage: error?.response?.data?.detail?.message,
            toastState: true,
            variant: "error",
          });
        },
      });
    }
    if (modifiedFPMappingRows.length > 0) {
      AddFPCategoryMappings(modifiedFPMappingRows, {
        onSuccess: () => {
          actions.setToast({
            toastMessage: ToastMessages.Record_Updated_Success,
            toastState: true,
          });
        },
        onError: (error) => {
          actions.setToast({
            toastMessage: error?.response?.data?.detail?.message,
            toastState: true,
            variant: "error",
          });
        },
      });
    }
    setMappingDialog(false);
    setProviderMappingState(false);
  };

  return (
    <>
      <Dialog
        variant={undefined}
        size="xl"
        title="Provider Mapping"
        open={mappingDialog}
        onCloseDialog={handleMappingClose}
        disablePortal={true}
        customFooter={
          <Button
            theme={theme}
            primary
            text="Save Changes"
            onClick={handleSubmit(onSubmit)}
          />
        }
        theme={theme}
        children={
          <>
            <DuoTabWrap theme={theme} className="duo-tab-wrap align-center sm">
              <form onSubmit={(e) => e.preventDefault()}>
                <DuoTab
                  theme={theme}
                  defaultTabIndex={0}
                  items={[
                    {
                      title: "F&I Products",
                      content: (
                        <FinancialInsuranceTable
                          form={form}
                          setUpdatedFPCategoryRows={setUpdatedFPCategoryRows}
                          financeTypes={financeTypes}
                          fpCategoryMappings={fpCategoryMappings}
                        />
                      ),
                    },
                    {
                      title: "Options",
                      content: (
                        <OptionsTable
                          form={form}
                          setUpdatedOptionRows={setUpdatedOptionRows}
                          optionMappings={optionMappings}
                        />
                      ),
                    },
                    {
                      title: "Fees",
                      content: (
                        <FeesTable
                          form={form}
                          setUpdatedFeesRows={setUpdatedFeesRows}
                          financeTypes={financeTypes}
                          statesData={statesData}
                          feesMappings={feesMappings}
                        />
                      ),
                    },
                  ]}
                />
              </form>
            </DuoTabWrap>
          </>
        }
      />
    </>
  );
};

export default ProviderMapping;
