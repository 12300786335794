import { createContext } from "react";
import {
  IAssetCondition,
  IAssetUsage,
  ICreditRating,
  ICurrencies,
  IFinancedAmount,
  ICompanyProfile,
  ITerms,
} from "Interface";

interface setupProps {
  assetConditions: IAssetCondition[];
  terms: ITerms[];
  creditRatings?: ICreditRating[];
  currencies?: ICurrencies[];
  lenderProfile: ICompanyProfile;
  AllFinancedAmounts: IFinancedAmount[];
  assetUsages?: IAssetUsage[];
  customerTypes?: any[];
  financeTypes?: any[];
  productTypes?: any[];
  assetCategories?: any[];
  assetType?: any[];
}

const SetupContext = createContext<setupProps>({
  assetConditions: [],
  terms: [],
  creditRatings: [],
  currencies: [],
  lenderProfile: {
    name: "",
    trading_name: "",
    url: "",
    id: 0,
    email: "",
    tax_id: "",
    mileage_unit: "",
    minimum_financed_amount: 0,
    default_currency: "",
    preferred_currency_ids: "",
    company_code: "",
    contact_person_email: "",
    contact_person_name: "",
    contact_person_phone: "",
  },
  AllFinancedAmounts: [],
  assetUsages: [],
  customerTypes: [],
  financeTypes: [],
  productTypes: [],
  assetCategories: [],
  assetType: [],
});

export default SetupContext;
