import { FC, useState } from "react";
import {
  UseQuery_getAllFeesCharts_filter,
  UseQuery_GetAllFeesCharts,
} from "services";
import MasterTable from "../shared/master-table";
import { ChartTypes, QueryKeys } from "Enums";
import { useQueryClient } from "react-query";
import { ICriteria } from "Interface";

const FeesTable: FC<{
  onOptionsClick: (key, value) => unknown;
  onEditCriteria: (data) => unknown;
}> = ({ onOptionsClick, onEditCriteria }) => {
  UseQuery_GetAllFeesCharts();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}`
  );
  const { data } = UseQuery_getAllFeesCharts_filter(columnFilters);
  const feeChartsFilter: any = data;
  const queryClient = useQueryClient();
  const feesCharts: Array<ICriteria> = queryClient.getQueryData(
    QueryKeys.GET_ALL_FEES_CHARTS
  );

  return (
    <MasterTable
      onOptionsClick={(key, value) => onOptionsClick(key, value)}
      onEditCriteria={(data) => onEditCriteria(data)}
      chartsFilter={feeChartsFilter}
      setColumnFilters={setColumnFilters}
      setPageSize={setPageSize}
      setPageNumber={setPageNumber}
      pageSize={pageSize}
      pageNumber={pageNumber}
      chartType={ChartTypes.FeesChart}
      criteria={feesCharts}
    />
  );
};

export default FeesTable;
