import { createContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { QueryKeys } from "Enums";
import { UseQuery_GetActiveDealers } from "services";

const DealerContext = createContext({
  dealer_code: "",
  updateDealerCode: (newValue: any) => {},
});

const DealerProvider = ({ children }) => {
  UseQuery_GetActiveDealers();

  const queryClient = useQueryClient();
  const dealerData: any = queryClient.getQueryData(
    QueryKeys.GET_ACTIVE_DEALERS
  );
  const [dealer_code, setDelaerCode] = useState<any>(
    localStorage.getItem("dealer_code")
  );

  const updateDealerCode = (newValue) => {
    var access_token_local = JSON.parse(localStorage.getItem("settings"));
    if (
      access_token_local?.role?.name === "Index Dealer Admin User" ||
      access_token_local?.role?.name == "DealerAdmin"
    ) {
      localStorage.setItem("dealer_code", newValue);
      setDelaerCode(newValue);
    } else {
      localStorage.setItem("dealer_code", newValue.id);
      setDelaerCode(newValue.id);
    }
  };

  useEffect(() => {
    if (
      !localStorage.getItem("dealer_code") ||
      localStorage.getItem("dealer_code") == "undefined"
    ) {
      localStorage.setItem("dealer_code", dealerData?.[0]?.dealer_code);
      setDelaerCode(dealerData?.[0]?.dealer_code);
    }
  }, [dealerData]);

  return (
    <DealerContext.Provider value={{ dealer_code, updateDealerCode }}>
      {children}
    </DealerContext.Provider>
  );
};

export { DealerContext, DealerProvider };
