import { useState, FC, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Dialog, Button, Grid } from "@ntpkunity/controls";
import { useUpdateVehicleDetailByVid } from "services/inventory-management.service";
import { useStoreContext } from "Stores/SetupStore/Store";
import { IVehicleDetail } from "Interface";
import InventoryOptionPopup from "./add-more-options-dialog";
import { useGetAddonByTrim } from "services/options.service";
import InventoryOptionsTable from "./options-listing-table";
import { useQueryClient } from "react-query";
import { QueryKeys } from "Enums";
const AttachedOptions: FC<{
  openPopup: boolean;
  vehicleData: IVehicleDetail;
  setopenOptionDialog: any;
  dealer_code: string;
  setModalClose?: any;
}> = ({
  openPopup,
  vehicleData,
  setopenOptionDialog,
  dealer_code,
  setModalClose,
}) => {
  const { actions } = useStoreContext();
  const { mutate: updateInventoryOption } = useUpdateVehicleDetailByVid();
  const [openAddMoreOptionDialog, setOpenAddMoreOptionDialog] = useState(false);
  const [isChangesSaved, setIsChangesSaved] = useState<boolean>(false);
  const [vehicleObject, setVehicleObject] = useState<any>(null);
  const { mutate: getDealerAddon, data: dealerOptions } = useGetAddonByTrim();
  const [optionObject, setOptionObject] = useState<any>(dealerOptions);
  const theme = useTheme();
  const [deleteData, setDeleteData] = useState<any[]>([]);
  const [listChanged, setlistChanged] = useState(false);
  const queryClient = useQueryClient();
  const handleCloseOptionDialog = () => {
    setModalClose(true);
    setopenOptionDialog(false);
    queryClient.invalidateQueries(QueryKeys.GET_ALL_INVENTORY_FILTER);
  };
  const handleOptionData = () => {
    let dealerids: any = deleteData?.map((x) => x.id);
    vehicleData.dealer_options = dealerids;
  };
  const handleData = () => {
    getDealerAddon(
      {
        data: `dealer_code=${dealer_code}&trim_code=${vehicleData.vehicle.model_code}`,
      },
      {
        onSuccess(response: any) {
          setOptionObject(response);
          setOpenAddMoreOptionDialog(true);
        },
      }
    );
  };
  // useEffect(() => {
  //   if (!openAddMoreOptionDialog && !isChangesSaved) {
  //     vehicleData.dealer_options = [];
  //   }
  // }, [openAddMoreOptionDialog]);
  return (
    <>
      <Dialog
        variant={undefined}
        size="xl"
        title="Dealer Options"
        open={openPopup}
        onCloseDialog={handleCloseOptionDialog}
        theme={theme}
        disablePortal
        customFooter={
          <>
            <Grid container theme={theme} spacing={2}>
              <Grid theme={theme} item xs={6} textAlign={"right"}>
                <Button
                  theme={theme}
                  secondary
                  id="addMoreOptionsBtn"
                  text="Add More options"
                  onClick={(_e: any) => {
                    setVehicleObject(vehicleData);
                    handleData();
                  }}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Button
                  theme={theme}
                  primary
                  id="listingSaveChanges"
                  text="Save Changes"
                  onClick={(_e: any) => {
                    handleOptionData();
                    updateInventoryOption(vehicleData, {
                      onSuccess() {
                        actions.setToast({
                          toastMessage: "Record Updated Successfully",
                          toastState: true,
                        });
                        queryClient.invalidateQueries(
                          QueryKeys.GET_ALL_INVENTORY_FILTER
                        );
                        setModalClose(true);
                        setopenOptionDialog(false);
                      },
                      onError(error: any) {
                        actions.setToast({
                          toastMessage: error.error.toString(),
                          toastState: true,
                          variant: "error",
                        });
                      },
                    });
                  }}
                  disabled={!listChanged}
                />
              </Grid>
            </Grid>
          </>
        }
      >
        <InventoryOptionsTable
          setDeleteData={setDeleteData}
          vehicleData={vehicleData}
          setlistChanged={setlistChanged}
          addMoreOptionPopupState={openAddMoreOptionDialog}
        />
      </Dialog>
      {vehicleObject && (
        <InventoryOptionPopup
          openPopup={openAddMoreOptionDialog}
          vehicleData={vehicleObject}
          setopenOptionDialog={setOpenAddMoreOptionDialog}
          optionData={optionObject}
          dealer_code={dealer_code}
          setIsChangesSaved={setIsChangesSaved}
        ></InventoryOptionPopup>
      )}
    </>
  );
};

export default AttachedOptions;
