import { useTheme } from "@mui/material";
import { Grid, Input, Select } from "@ntpkunity/controls";
import { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
export const FinanceRates: FC<{
  form: any;
  popUpOpenState: string;
  disabled: boolean;
  setCompanyFieldState?: any;
}> = ({ form, disabled, setCompanyFieldState }) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = form;
  const theme = useTheme();

  return (
    <>
      <Grid theme={theme} container columnSpacing={2}>
        <Grid theme={theme} item xs={6}>
          <Controller
            name="finance_downPayment"
            control={control}
            defaultValue={"0.00"}
            render={({ field: { value, onChange } }) => (
              <Input
                theme={theme}
                fullWidth={true}
                placeholder={"Type here.."}
                label={"Down Payment (Optional)"}
                type="text"
                startAdornment=""
                id="markupDrawer"
                disabled={disabled}
                onChange={(e) => {
                  onChange(e);
                  setCompanyFieldState(true);
                }}
                value={value}
                endAdornment={<span className="MuiInputAdornment-root">%</span>}
                masking={true}
                maskNumeric={true}
                maskDecimalScale={6}
                maskAllowNegative
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={6}>
          <Controller
            name="finacnce_rvBalloon"
            control={control}
            defaultValue={"0.00"}
            render={({ field: { value, onChange } }) => (
              <Input
                theme={theme}
                fullWidth={true}
                placeholder={"Type here.."}
                label={"RV/Balloon (Optional)"}
                type="text"
                startAdornment=""
                id="markupDrawer"
                disabled={disabled}
                onChange={(e) => {
                  onChange(e);
                  setCompanyFieldState(true);
                }}
                value={value}
                endAdornment={<span className="MuiInputAdornment-root">%</span>}
                masking={true}
                maskNumeric={true}
                maskDecimalScale={6}
                maskAllowNegative
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={6}>
          <Controller
            name="finance_customerRate"
            control={control}
            defaultValue={"0.00"}
            render={({ field: { value, onChange } }) => (
              <Input
                theme={theme}
                fullWidth={true}
                placeholder={"Type here.."}
                label={"Customer Rate (Optional)"}
                type="text"
                startAdornment=""
                disabled={disabled}
                id="markupDrawer"
                onChange={(e) => {
                  onChange(e);
                  setCompanyFieldState(true);
                }}
                value={value}
                endAdornment={<span className="MuiInputAdornment-root">%</span>}
                masking={true}
                maskNumeric={true}
                maskDecimalScale={6}
                maskAllowNegative
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};
