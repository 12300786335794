import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ICompany } from "Interface";
import { QueryKeys, ToastMessages } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";

export const getCompanyByTenantId = (tenant_id: number) => {
  return ConfigurationAPI.get(`${"/company/by-tenant-id"}/${tenant_id}`).then(
    (res) => {
      return res.data;
    }
  );
};

export const UseQuery_getCompanyByTenantId = (tenant_id: number) => {
  return useQuery(
    QueryKeys.GET_COMPANY_PROFILE,
    async () => {
      return getCompanyByTenantId(tenant_id).then((data) => {
        return data;
      });
    },
    { enabled: !!tenant_id }
  );
};

export const UseQuery_getDocumentsByCompanyId = () => {
  return useQuery(QueryKeys.Get_All_COMPANY_DOCUMENTS, async () => {
    return ConfigurationAPI.get(`/documents`).then((res) => {
      return res.data;
    });
  });
};

export const UseMutation_AddCompanyProfile = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();

  return useMutation<ICompany>(
    (company) => {
      return ConfigurationAPI.post("/company/create-company", company);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_COMPANY_PROFILE);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_UpdateCompanyProfile = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();

  return useMutation<ICompany>((company: any) => {
    return ConfigurationAPI.patch(
      `/company/${company.company_profile.id}`,
      company
    );
  }, {});
};

export const UseMutation_CheckDuplicateslugName: any = () => {
  const { data, isLoading, mutate } = useMutation(
    (body: any) => {
      let params = body.data ? `${body.data}` : "";
      return ConfigurationAPI.get<any>(
        params ? `${"/check-slug/slug"}?${params}` : "/check-slug/slug"
      );
    },
    {
      onSuccess(_response: any) {},
    }
  );
  return { data, isLoading, mutate };
};

export const UseMutation_UpdateCompanyPrice = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<ICompany>(
    (body: any) => {
      return ConfigurationAPI.patch(
        `/company/update-company-pricing/${body?.company_id}`,
        body
      );
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.Get_All_COMPANY_DOCUMENTS);
        queryClient.invalidateQueries(QueryKeys.GET_COMPANY_PROFILE);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};
