export enum IntegrationType {
  PAYMENT_PROCESSING = "Payment Processing",
  CUSTOMER_MANAGEMENT = "Customer Management",
  INVENTORY_MANAGEMENT = "Inventory Management",
  FNI_PRODUCTS = "F&I Products",
  FRAUD_IDENTIFICATION = "Fraud Identification",
  IDENTITY_VERIFICATION = "Identity Verification",
  LOCATION_SERVICE = "Location Services",
  CO_BROWSING = "Co-browsing",
  TRADEIN_VALUATION = "Trade In Valuation",
  PURCHASE_INSURANCE = "Purchase Insurance",
  DEAL_PUSH = "Deal Push",
  LEAD_GENERATION_AND_MANAGEMENT = "Lead Generation and Management",
  CALCULATION_AND_TAXATION = "Calculation & Taxation",
  E_SIGNATURE = "E-Signatures",
  // LENDER_MANAGEMENT = "Dealer-Lender Association",
  DEALER_LENDER_ASSOCIATION = "Dealer-Lender Association",
  INVENTORY_CHECK = "Inventory Check",
  PRICING_PROGRAM = "Pricing Program",
  CREDIT_DECISIONING = "Credit Decisioning",
  CONTRACTING = "Contracting",
  CREDIT_BUREAU = "Credit Bureau",
  TAGGING = "Tagging",
}

export enum Providers {
  STRIPE = "Stripe",
  PLAID = "Plaid",
  CDK = "Cdk",
  TEKION = "Tekion",
  DEALERTRACK = "DealerTrack",
  SHIFTDIGITAL = "ShiftDigital",
  BMW = "BMW",
  SOCURE = "Socure",
  MITEK = "MiTek",
  GOOGLE = "Google",
  UPSCOPE = "upscope",
  KBB = "KBB",
  WAY = "Way",
  MARKETSCAN = "MarketScan",
  DOCUSIGN = "Docusign",
  ROUTEONE = "RouteOne",
  DEALER_TRACKML = "DealerTrackML",
  DEALER_TRACK_LENDER = "DealerTrackLender",
  PEN = "PEN",
  BMW_DEALS = "BMWDeals",
  BMW_PRICING = "BMW",
  ASCENT = "Ascent",
  TRANSUNION = "TransUnion",
  EXPERIAN = "Experian",
  EQUIFAX = "Equifax",
  SHIFT_DIGITAL = "Shift Digital",
}

export enum IntegrationLevel {
  COMPANY = "company",
  DEALER = "dealer",
  BOTH = "both",
}
