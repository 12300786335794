import { ConfigurationAPI } from "services";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import { ILender, ILenderFilter } from "Interface";

export const getAllLendersFromServer = () => {
  return ConfigurationAPI.get("/lender").then((res) => {
    return res.data;
  });
};

export const getAllLenderFilterFromServer = (columnFilters: string) => {
  return ConfigurationAPI.get(
    `/all-lenders/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_getAllLender = () => {
  return useQuery(QueryKeys.GET_ALL_LENDER, async () => {
    return getAllLendersFromServer().then((data) => {
      return data;
    });
  });
};

export const UseQuery_getAllLender_filter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_LENDER_FILTER, columnFilters],
    async () => {
      return getAllLenderFilterFromServer(columnFilters).then((data) => {
        return data;
      });
    },
    { enabled: !!columnFilters, keepPreviousData: true }
  );
};

export const UseMutation_AddLender = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<ILender>(
    (documentType) => {
      return ConfigurationAPI.post("/lender/create-lender", documentType);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_LENDER_FILTER),
          queryClient.invalidateQueries(QueryKeys.GET_ALL_LENDER),
          actions.setToast({
            toastMessage: ToastMessages.Record_Added_Success,
            toastState: true,
          });
      },
    }
  );
};

export const UseMutation_UpdateLender = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<ILenderFilter>(
    (lenderId: any) => {
      return ConfigurationAPI.patch(
        `/lender/update-lender/${lenderId.id}`,
        lenderId
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_LENDER_FILTER),
          queryClient.invalidateQueries(QueryKeys.GET_ALL_LENDER),
          actions.setToast({
            toastMessage: ToastMessages.Record_Updated_Success,
            toastState: true,
          });
      },
    }
  );
};

export const UseMutation_ImportDealerLenders = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<any>(
    (body: any) => {
      return ConfigurationAPI.post(`/import/dealer-lenders/`, body);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_LENDER_FILTER),
          queryClient.invalidateQueries(QueryKeys.GET_ALL_LENDER),
          actions.setToast({
            toastMessage: ToastMessages.Lender_Imported_Successfully,
            toastState: true,
          });
      },
    }
  );
};
