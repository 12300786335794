import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const BorderedTableWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.custom-table-wrap": {
    ".u-table-wrap": {
      ".u-table-container": {
        maxHeight: "calc(100vh - 390px)",
        borderRadius: 8,
        ".u-table": {
          ".u-table-head": {
            tr: {
              backgroundColor: "#f2f2f2",
              td: {
                backgroundColor: "transparent",
                borderColor: "#cccccc",
                borderRight: "1px solid #cccccc",
                height: "40px",
                ":last-child": {
                  borderRight: "0",
                },
              },
            },
          },
          ".u-table-body": {
            "tr:nth-of-type(odd)": {
              td: {
                backgroundColor: "transparent",
              },
            },
            tr: {
              td: {
                borderRight: "1px solid #cccccc",
                "&.input-cell": {
                  padding: 0,
                  ".error-state": {
                    backgroundColor: "rgba(255, 59, 48, 0.08)",
                    height: "40px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                },
                ".u-form-group": {
                  ".u-form-control": {
                    height: 40,
                    padding: "8px 16px",
                    "&.u-select": {
                      paddingRight: 40,
                    },
                    ".MuiInputBase-input": {
                      fontSize: theme.typography.body2.fontSize,
                      padding: 0,
                    },
                    ".MuiSelect-select": {
                      minHeight: "100%",
                      height: "100%",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                      borderRadius: 0,
                      borderWidth: 0,
                    },
                  },
                  ".MuiFormHelperText-root": {
                    display: "none",
                  },
                },
                ":last-child": {
                  borderRight: "0",
                },
                ":first-child": {
                  backgroundColor: "#f2f2f2",
                },
              },
              ":hover": {
                td: {
                  backgroundColor: "transparent !important",
                },
                "td:first-child": {
                  backgroundColor: "#f2f2f2 !important",
                },
              },
            },
          },
          ".sr-num": {
            width: 40,
            minWidth: 40,
            fontWeight: 600,
          },
        },
      },
    },
  },
}));
