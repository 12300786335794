import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { Box, Button } from "@ntpkunity/controls";
import { DataTableWrap } from "@sharedComponents";
import { ActionOptions, QueryKeys } from "Enums";
import FeesTable from "./fees-table";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";
import { AddNewFeesChart } from "components";
import { useQueryClient } from "react-query";
import {
  IInterestChartCriteria,
  IAssetCondition,
  IFinancedAmount,
  ITerms,
  IFinanceType,
  ICustomerType,
  IAssetCategory,
  IAssetType,
} from "Interface";

const FeesChart: FC = () => {
  const queryClient = useQueryClient();

  const productTypes: Array<any> = queryClient.getQueryData(
    QueryKeys.Get_All_PRODUCT_TYPE
  );
  const financeTypes: Array<IFinanceType> = queryClient.getQueryData(
    QueryKeys.Get_All_FINANCE_TYPE
  );
  const customerTypes: Array<ICustomerType> = queryClient.getQueryData(
    QueryKeys.Get_All_CUSTOMER_TYPE
  );
  const assetCategories: Array<IAssetCategory> = queryClient.getQueryData(
    QueryKeys.Get_All_ASSET_CATEGORY
  );
  const assetType: Array<IAssetType> = queryClient.getQueryData(
    QueryKeys.Get_All_ASSET_TYPE
  );
  const AllFinancedAmounts: Array<IFinancedAmount> = queryClient.getQueryData(
    QueryKeys.GET_ALL_FINANCED_AMOUNTS
  );
  const terms: Array<ITerms> = queryClient.getQueryData(
    QueryKeys.GET_ALL_TERMS
  );
  const assetConditions: Array<IAssetCondition> = queryClient.getQueryData(
    QueryKeys.Get_All_Asset_Conditions
  );

  const [feesChartState, setfeesChartState] = useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>("Add New Subsidy");
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [actionData, setActionData] =
    useState<IInterestChartCriteria>(undefined);

  const [state, dispatch] = useSetupsStore();

  useEffect(() => {
    if (feesChartState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [feesChartState]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD_NEW_CHART);
        setActionTitle("Add New Chart");
        setActionData(value);
        setfeesChartState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  const onOptionsClick = (key, value) => {
    switch (key) {
      case ActionOptions.ADD_NEW_CHART:
        const criteriaData = {
          chart: value,
          criteria: null,
          chartId: 0,
        };
        setActionType(ActionOptions.ADD_NEW_CHART);
        setActionTitle("Add New Criteria");
        setActionData(criteriaData);
        dispatch(setOpenDrawer(true));
        setfeesChartState(true);
        return;
      case ActionOptions.RENAME_CHART:
        const editData = {
          chart: value,
          criteria: null,
          chartId: 0,
        };
        setActionType(ActionOptions.RENAME_CHART);
        setActionTitle("Edit Chart");
        setActionData(editData);
        dispatch(setOpenDrawer(true));
        setfeesChartState(true);
        return;
      case ActionOptions.ADD_CRITERIA:
        const data = {
          chart: value,
          criteria: null,
          chartId: 0,
        };
        setActionType(ActionOptions.ADD_CRITERIA);
        setActionTitle("Add Criteria");
        setActionData(data);
        dispatch(setOpenDrawer(true));
        setfeesChartState(true);
        return;
      default:
        return;
    }
  };
  const onEditCriteria = (data) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Criteria");
    setActionData(data);
    dispatch(setOpenDrawer(true));
    setfeesChartState(true);
  };

  const theme = useTheme();
  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide sh-fixed-cell spr-border" theme={theme} />
        </Box>
        <FeesTable
          onOptionsClick={(key, value) => onOptionsClick(key, value)}
          onEditCriteria={(data) => onEditCriteria(data)}
        />
        <Button
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Chart"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
        {feesChartState && (
          <AddNewFeesChart
            openPopUp={feesChartState}
            setPopUpState={setfeesChartState}
            title={actionTitle}
            actionType={actionType}
            feeChartData={actionData}
            productTypes={productTypes}
            financeTypes={financeTypes}
            customerTypes={customerTypes}
            assetCategories={assetCategories}
            assetType={assetType}
            AllFinancedAmounts={AllFinancedAmounts}
            terms={terms}
            assetConditions={assetConditions}
          />
        )}
      </DataTableWrap>
    </>
  );
};
export default FeesChart;
